import React from "react";
import PageTemplate from "./base";
import imgHero from "../../assets/image/industries/call-centers.jpeg";

const Page = () => {
  return (
    <>
      <PageTemplate
        seoTitle="Free Scheduling Software for Call Centers & Customer Service | Camelo"
        metaDescription="Schedule and manage call center teams in minutes. Fill every shift with a single tap. Communicate instantly and solve problems with your team in the Camelo app."
        header="Schedule and manage call center teams in minutes."
        subHeader="Camelo is a better way to schedule, connect, and manage your team."
        imgUrl={imgHero}
      />
    </>
  );
};

export default Page;
